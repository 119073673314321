import { getToken } from '../../../helpers/auth';
import {
    cartEndpoint,
    country,
    language as languageCode,
} from '../../../helpers/settings';
import { CartBackendError } from '../customError';
import type { components } from '../types';

export type AddItemInputCS = components['schemas']['ItemRequest'];
export type CSCartResponse =
    | components['schemas']['CartResponse']
    | undefined
    | null;
export type CartResponse = {
    cart: CSCartResponse;
    error: CartBackendError | undefined;
};

const baseUrl = `${cartEndpoint}/${country}`;

const parameters = new URLSearchParams({
    //Needed for fetching cart
    fetchItemInfo: 'true',
    fetchCartContext: 'false',
    fetchIndicativeAvailability: 'false',
    fetchPrice: 'true',
    shoppingProfile: 'ONLINE',
    group: 'DEFAULT',
}).toString();

const getHeaders = (accessToken: string) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'X-Consumer-Name': 'Web Cart',
        'Accept-Language': languageCode,
    };

    return new Headers(headers);
};

const getBody = (payload: {
    items?: AddItemInputCS[];
    plans?: Array<{ code: string; quantity: number }>;
    userId?: string;
}) => {
    const populateCart = {
        fetchCartContext: false,
        fetchItemInfo: true,
        fetchIndicativeAvailability: false,
        fetchPrice: true,
    };
    const body = {
        shoppingProfile: 'ONLINE',
        group: 'DEFAULT',
        populateCart,
        ...payload,
    };

    return JSON.stringify(body);
};

type BadRequestError = {
    code: 400;
} & components['schemas']['ApiBadRequestError'];

type UnauthorizedError = {
    code: 401;
} & components['schemas']['ApiUnauthorizedError'];

type InternalServerError = {
    code: 500;
} & components['schemas']['ApiInternalServerError'];

type ErrorResponses = BadRequestError | UnauthorizedError | InternalServerError;

const handleError = (data: ErrorResponses) => {
    switch (data.code) {
        case 400:
            return {
                cart: null,
                error: new CartBackendError(
                    data?.title ?? 'unknown 400',
                    (data?.errors ?? []).map(
                        (e: {
                            code?: string | undefined;
                            description?: string | null | undefined;
                        }) => ({
                            code: e.code || '',
                        })
                    ),
                    (data?.items ?? []).map(item => ({
                        itemNo: item.itemNo || '',
                        description: item.description || '',
                    })),
                    data?.reference
                ),
            };
        case 401:
            return {
                cart: null,
                error: new CartBackendError(
                    data?.title ?? 'unknown 401',
                    (data?.errors ?? []).map(
                        (e: {
                            code?: string | undefined;
                            description?: string | null | undefined;
                        }) => ({
                            code: e.code || '',
                        })
                    ),
                    [],
                    data?.reference
                ),
            };
        case 500:
            return {
                cart: null,
                error: new CartBackendError(
                    data?.title ?? 'unknown 500',
                    (data?.errors ?? []).map(
                        (e: {
                            code?: string | undefined;
                            description?: string | null | undefined;
                        }) => ({
                            code: e.code || '',
                        })
                    ),
                    [],
                    data?.reference
                ),
            };
        default:
            return {
                cart: null,
                error: new CartBackendError(
                    'No data',
                    [
                        {
                            code: 'UNKNOWN_ERROR',
                        },
                    ],
                    [],
                    'unknown reference'
                ),
            };
    }
};

export const fetchCart = async (): Promise<CartResponse> => {
    try {
        const token = await getToken();

        const resp = await fetch(`${baseUrl}?${parameters}`, {
            method: 'GET',
            headers: getHeaders(token),
        });
        const data = await resp.json();

        // Intentionally return empty cart when the current cart is not found
        if (!resp.ok && data?.errors?.[0]?.code === 'CART_NOT_FOUND') {
            return {
                cart: null,
                error: undefined,
            };
        }

        if (resp.ok) {
            return {
                cart: data,
                error: undefined,
            };
        }
        return handleError({ code: resp.status, ...data });
    } catch (e) {
        return {
            cart: null,
            error: new CartBackendError('Network Error', [
                { code: 'NETWORK_ERROR' },
            ]),
        };
    }
};

export const addItems = async (
    items: AddItemInputCS[]
): Promise<CartResponse> => {
    try {
        const token = await getToken();
        const resp = await fetch(`${baseUrl}/items`, {
            method: 'POST',
            headers: getHeaders(token),
            body: getBody({ items }),
        });

        const data = await resp.json();

        if (resp.ok) {
            return {
                cart: data.cart,
                error: undefined,
            };
        }
        return handleError({ code: resp.status, ...data });
    } catch (e) {
        return {
            cart: null,
            error: new CartBackendError('Network Error', [
                { code: 'NETWORK_ERROR' },
            ]),
        };
    }
};

export const removeItems = async (
    itemNos: Array<string>
): Promise<CartResponse> => {
    try {
        const token = await getToken();
        const stringOfItemNos = itemNos.toString();

        const resp = await fetch(
            `${baseUrl}/items?itemNumbers=${stringOfItemNos}&${parameters}`,
            {
                method: 'DELETE',
                headers: getHeaders(token),
            }
        );

        const data = await resp.json();

        if (resp.ok) {
            return {
                cart: data.cart,
                error: undefined,
            };
        }
        return handleError({ code: resp.status, ...data });
    } catch (e) {
        return {
            cart: null,
            error: new CartBackendError('Network Error', [
                { code: 'NETWORK_ERROR' },
            ]),
        };
    }
};

export const updateItems = async (
    items: AddItemInputCS[]
): Promise<CartResponse> => {
    try {
        const token = await getToken();

        const resp = await fetch(`${baseUrl}/items`, {
            method: 'PUT',
            headers: getHeaders(token),
            body: getBody({ items }),
        });

        const data = await resp.json();

        if (resp.ok) {
            return {
                cart: data.cart,
                error: undefined,
            };
        }
        return handleError({ code: resp.status, ...data });
    } catch (e) {
        return {
            cart: null,
            error: new CartBackendError('Network Error', [
                { code: 'NETWORK_ERROR' },
            ]),
        };
    }
};

export const addPlannerCodes = async (
    plans: Array<{ code: string; quantity: number }>
): Promise<CartResponse> => {
    try {
        const token = await getToken();

        const resp = await fetch(`${baseUrl}/plans`, {
            method: 'POST',
            headers: getHeaders(token),
            body: getBody({ plans }),
        });

        const data = await resp.json();

        if (resp.ok) {
            return {
                cart: data.cart,
                error: undefined,
            };
        }

        return handleError({ code: resp.status, ...data });
    } catch (e) {
        return {
            cart: null,
            error: new CartBackendError('Network Error', [
                { code: 'NETWORK_ERROR' },
            ]),
        };
    }
};

export const updatePlanners = async (
    plans: Array<{ code: string; quantity: number }>
): Promise<CartResponse> => {
    try {
        const token = await getToken();

        const resp = await fetch(`${baseUrl}/plans`, {
            method: 'PUT',
            headers: getHeaders(token),
            body: getBody({ plans }),
        });

        const data = await resp.json();

        if (resp.ok) {
            return {
                cart: data.cart,
                error: undefined,
            };
        }

        return handleError({ code: resp.status, ...data });
    } catch (e) {
        return {
            cart: null,
            error: new CartBackendError('Network Error', [
                { code: 'NETWORK_ERROR' },
            ]),
        };
    }
};

export const removePlans = async (
    codes: Array<string>
): Promise<CartResponse> => {
    try {
        const token = await getToken();

        const stringOfPlannerCodes = codes.toString();

        const resp = await fetch(
            `${baseUrl}/plans?codes=${stringOfPlannerCodes}&${parameters}`,
            {
                method: 'DELETE',
                headers: getHeaders(token),
            }
        );

        const data = await resp.json();

        if (resp.ok) {
            return {
                cart: data.cart,
                error: undefined,
            };
        }
        return handleError({ code: resp.status, ...data });
    } catch (e) {
        return {
            cart: null,
            error: new CartBackendError('Network Error', [
                { code: 'NETWORK_ERROR' },
            ]),
        };
    }
};
