import { type CartOperationResult, getCart } from './apis/cart/index.ts';
import { getToken, getTokenDetails } from './helpers/auth.ts';
import fetchCartPhraseIfNeeded from './helpers/fetchCartPhraseIfNeeded.ts';
import getMarketSettings from './helpers/getMarketSettings.ts';
import { getCookie, updateHeaderBadge } from './helpers/headerBadge.ts';
import {
    initOptimizely,
    initOptimizelySecondary,
    optimizely,
} from './helpers/optimizely.ts';
import {
    COUNTRIES_WITH_BUSINESS_CART_URL,
    businessCustomerCartUrl,
    country,
    language,
    shoppingCartUrl,
} from './helpers/settings.ts';
import {
    clearCartStore,
    fetchValidCartData,
    fetchValidCartItemsData,
    subscribeCart,
} from './helpers/store';
import {
    ADD_TO_CART,
    AGENT_LOADED,
    REMOVE_ITEMS_FROM_CART,
    UPDATE_CART,
    UPDATE_CART_COUNT,
    UPDATE_CART_ITEMS_QUANTITY,
    UPDATE_DATA,
    publish,
} from './pubsub';
import type {
    CartData,
    CartEvent,
    CartPayload,
    MinimalCartItem,
    RemoveItemsFromCartEvent,
    RemoveItemsFromCartPayload,
    SettingsDClass,
    UpdateCartCountEvent,
    UpdateCartCountPayload,
    UpdateDataEvent,
    UpdateDataPayload,
    UpdateQuantitiesEvent,
    UpdateQuantitiesPayload,
} from './types.ts';

export let cachedCartResult: CartOperationResult;
export let marketSettings: SettingsDClass;

const flexiCartExperiments = [
    'frakta-687_flexi_cart_w__double_cta',
    '_frakta-1357__flexi_cart_single_cta_global_v4',
];

export const isInAnyFlexiCartVariation = (wrapper = optimizely) =>
    flexiCartExperiments.some(
        experiment => !!wrapper?.getVariation(experiment)
    );

export const isFlexiCartExperimentOn = (wrapper = optimizely) =>
    flexiCartExperiments.some(experiment => {
        const variation = wrapper?.getVariation(experiment);
        return variation === 'b' || variation === 'c';
    });

export const updateCachedCart = (cartResult: CartOperationResult) => {
    // Only update cached cart if in variation
    if (isFlexiCartExperimentOn()) {
        cachedCartResult = cartResult;
        updateHeaderBadge(cartResult.cart.quantity);
    }
};

export const handleCartRedirect = async (source: string) => {
    const flexiCartSources = ['header', 'internal'];
    const isFlexiSource = flexiCartSources.includes(source);
    let url = shoppingCartUrl;
    let activationResult: Record<string, string> = {};
    // Activate Flexi-Cart experiment(s)
    if (optimizely && isFlexiSource) {
        activationResult = Object.fromEntries(
            optimizely.activates(flexiCartExperiments)
        );
    }

    // Check for the results of activation instead of using wrapper.getVariation
    const isInFlexiVariation = flexiCartExperiments.some(experiment => {
        const variant = activationResult[experiment];
        return variant === 'b' || variant === 'c';
    });
    // If the flexi-cart experiment is active, the source is allowed,
    // and the current path is not '/shoppingcart', open the flexi-cart and exit.
    if (
        isInFlexiVariation &&
        isFlexiSource &&
        !window.location.pathname.includes('/shoppingcart')
    ) {
        const module = await import('./handlers');
        await module.handleOpenCartModal(cachedCartResult);
        return;
    }
    if (COUNTRIES_WITH_BUSINESS_CART_URL.includes(country)) {
        try {
            const token = await getToken();
            const userDetails = getTokenDetails(token);
            if (userDetails?.profileType === 'business') {
                url = businessCustomerCartUrl;
            }
        } catch (e) {
            reportError('Unable to redirect customer to business cart');
        }
    }
    window.location.href = url;
};

const _handleAddToCartSubscription = async (payload: CartPayload) => {
    const module = await import('./handlers');
    await module.handleAddToCartSubscription(payload);
};

const _handleUpdateDataSubscription = async (payload: UpdateDataPayload) => {
    const module = await import('./handlers');
    module.handleUpdateDataSubscription(payload);
};

const _handleUpdateCartCountSubscription = async (
    payload: UpdateCartCountPayload
) => {
    const module = await import('./handlers');
    await module.handleUpdateCartCount(payload);
};

const _handleRemoveItemsFromCartSubscription = async (
    payload: RemoveItemsFromCartPayload
) => {
    const module = await import('./handlers');
    await module.handleRemoveItems(payload);
};

const _handleUpdateQuantitiesSubsription = async (
    payload: UpdateQuantitiesPayload
) => {
    const module = await import('./handlers');
    await module.handleUpdateQuantities(payload);
};

function initCartAgent() {
    if (window.ikea.shoppingCart === undefined) {
        // Cache to avoid multiple requests simultaneuosly
        let getCartPromise: Promise<Array<MinimalCartItem>> | null = null;
        let getCartDataPromise: Promise<CartData> | null = null;
        window.ikea.shoppingCart = {
            getCart: async () => {
                try {
                    getCartPromise =
                        getCartPromise ?? fetchValidCartItemsData();
                    return await getCartPromise;
                } finally {
                    getCartPromise = null;
                }
            },
            getCartData: async () => {
                try {
                    getCartDataPromise =
                        getCartDataPromise ?? fetchValidCartData();
                    return await getCartDataPromise;
                } finally {
                    getCartDataPromise = null;
                }
            },
            open: async ({ source }: { source: string }) => {
                await handleCartRedirect(source);
            },
        };
    }
    window.ikea.shoppingCart.cartAgent = true;
    /**
     * Whenever the stored data is updated, we publish a pubsub event
     */
    subscribeCart(d => publish(UPDATE_CART, d));
    window.ikea.pubsub.subscribe<CartEvent>(
        ADD_TO_CART,
        _handleAddToCartSubscription
    );
    window.ikea.pubsub.subscribe<UpdateDataEvent>(
        UPDATE_DATA,
        _handleUpdateDataSubscription
    );
    window.ikea.pubsub.subscribe<UpdateCartCountEvent>(
        UPDATE_CART_COUNT,
        _handleUpdateCartCountSubscription
    );
    window.ikea.pubsub.subscribe<UpdateQuantitiesEvent>(
        UPDATE_CART_ITEMS_QUANTITY,
        _handleUpdateQuantitiesSubsription
    );
    window.ikea.pubsub.subscribe<RemoveItemsFromCartEvent>(
        REMOVE_ITEMS_FROM_CART,
        _handleRemoveItemsFromCartSubscription
    );
}

const cartCount = getCookie(`IRMW_CART_COUNT_${country.toUpperCase()}`);
const isCartEmpty = cartCount === '0' || cartCount === '';
const isDev = process.env.NODE_ENV === 'development';

export async function init() {
    if (window.ikea) {
        initCartAgent();
        marketSettings = await getMarketSettings();
        await initOptimizely();
        //TODO: Remove initiation of secondary wrapper once cross-web test has been concluded
        await initOptimizelySecondary();
        await fetchCartPhraseIfNeeded();
        const cartSurvey = await import(
            './components/LeaveCartSurvey/index.tsx'
        );
        cartSurvey.initCartSurvey();
        // Only fetch cart if bucketed in flexi-cart experiment & cart is not empty
        if ((isFlexiCartExperimentOn(optimizely) && !isCartEmpty) || isDev) {
            cachedCartResult = await getCart();
        }
    }
    /**
     *  When a user leaves certain pages we need to clear the store, since it likely contains outdated information.
     * Example pages:
     *  - the login page, where another account might now be used
     */
    const clearStorePaths = [
        `/${country}/${language}/profile`,
        `/${country}/${language}/login`,
    ];
    if (clearStorePaths.some(path => window.location.pathname.includes(path))) {
        window.addEventListener('beforeunload', () => {
            clearCartStore();
        });
    }
    publish(AGENT_LOADED, null);
}
